var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-developers animatedBox"},[_c('div',{staticClass:"container fluid"},[_c('filter-panel',{on:{"clear":_vm.clearFilter,"filter":_vm.filterData},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[_c('div',{staticClass:"p-2"},[_c('fd-input',{staticClass:"col-12 px-1 mb-2",attrs:{"label":"Name","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(_vm.filter['name[partial]']),callback:function ($$v) {_vm.$set(_vm.filter, 'name[partial]', $$v)},expression:"filter['name[partial]']"}})],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.developerTableColumns,"rows":_vm.developerTableData,"totalRows":_vm.developerTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('fd-button',{staticClass:"mr-2",on:{"click":function($event){return _vm.toBankAccountManagement(props.row.id)}}},[_c('i',{staticClass:"fas fa-money-check mr-1"}),_vm._v(" Bank Accounts ")]),_c('fd-button',{staticClass:"ml-2",on:{"click":function($event){return _vm.toManageUsers(props.row.id)}}},[_c('i',{staticClass:"fas fa-users"})]),_c('fd-button',{staticClass:"ml-2",on:{"click":function($event){return _vm.toEditDeveloper(props.row.id)}}},[_c('i',{staticClass:"fas fa-pen"})]),_c('fd-button',{staticClass:"bordered danger ml-2",on:{"click":function($event){return _vm.deleteDeveloper(props.row.id)}}},[_c('i',{staticClass:"fas fa-trash"})])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptyarea"},slot:"emptyarea"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('fd-button',{staticClass:"main mr-2",on:{"click":_vm.toAddDeveloper}},[_c('i',{staticClass:"fas fa-plus"})]),_c('fd-button',{staticClass:"toggle",class:{ active: _vm.isFilterActive },on:{"click":_vm.filterToggle}},[_c('i',{staticClass:"fas fa-filter"})])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }