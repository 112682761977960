<template>
  <div class="manage-developers animatedBox">
    <div class="container fluid">
      <!-- ============================= Filter ============================ -->
      <filter-panel
        v-model="isFilterActive"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 px-1 mb-2"
            label="Name"
            type="text"
            @keyup.enter.native="filterData"
          >
          </fd-input>
        </div>
      </filter-panel>
      <!-- ========================= Table Listing ========================= -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="developerTableColumns"
          :rows="developerTableData"
          :totalRows="developerTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
        >
          <div slot="emptyarea">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <fd-button class="main mr-2" @click="toAddDeveloper">
              <i class="fas fa-plus"></i>
            </fd-button>
            <fd-button
              :class="{ active: isFilterActive }"
              class="toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </fd-button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <!-- Bank Account -->
              <fd-button
                class="mr-2"
                @click="toBankAccountManagement(props.row.id)"
              >
                <i class="fas fa-money-check mr-1"></i>
                Bank Accounts
              </fd-button>
              <!-- Manage Users -->
              <fd-button class="ml-2" @click="toManageUsers(props.row.id)">
                <i class="fas fa-users"></i>
              </fd-button>
              <!-- Edit Developer -->
              <fd-button class="ml-2" @click="toEditDeveloper(props.row.id)">
                <i class="fas fa-pen"></i>
              </fd-button>
              <!-- Delete Developer -->
              <fd-button
                class="bordered danger ml-2"
                @click="deleteDeveloper(props.row.id)"
              >
                <i class="fas fa-trash"></i>
              </fd-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-developers",
  components: {},
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      developerTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false
        },
        {
          label: "Name",
          field: "name"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      developerTableData: [],
      developerTablePagination: {},
      isFilterActive: false,
      filter: {
        "name[partial]": ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.getAllDevelopers({ limit: this.tableParams.perPage });
  },
  methods: {
    toBankAccountManagement(id) {
      this.$router.push({
        name: "ManageDeveloperBankAccounts",
        params: { id: id }
      });
    },
    toManageUsers(id) {
      this.$router.push({
        name: "ManageDeveloperUsers",
        params: { developerId: id }
      });
    },
    // ========================= Table related methods =========================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllDevelopers();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllDevelopers();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllDevelopers();
    },
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllDevelopers();
    },
    toAddDeveloper() {
      this.$router.push({ name: "ManageDevelopersAdd" });
    },
    toEditDeveloper(id) {
      this.$router.push({ name: "ManageDevelopersEdit", params: { id: id } });
    },
    // ============================== API Related ==============================
    async getAllDevelopers() {
      try {
        this.$store.commit("setIsLoading", true);

        let filteredParam = this.$cleanQueryParam(this.filter);

        let response = await this.$store.dispatch(
          "manageDevelopers/getAllDevelopers",
          {
            ...filteredParam,
            page: this.tableParams.page,
            limit: this.tableParams.perPage
          }
        );

        this.developerTableData = this._.cloneDeep(response.data);
        this.developerTablePagination = this._.cloneDeep(
          response.meta.pagination
        );
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        throw error;
      }
    },

    async deleteDeveloper(id) {
      let confirmDelete = await this.$confirm({
        type: "alert",
        message:
          "Are you sure you want to delete this developer? This action cannot be undone."
      });

      if (confirmDelete) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.$store.dispatch("manageDevelopers/deleteDeveloper", id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Deleted",
            text: "Developer has been deleted successfully."
          });
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: " An unexpected error occured. Please try again later."
          });
          this.$reportError(error);
          throw error;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.manage-developers {
}
</style>
